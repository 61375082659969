/* eslint-disable no-unused-vars */
import { cloneDeep, intersection } from "lodash";
import dayjs from "dayjs";

export enum QUESTIONNAIRE_ITEM_TYPE {
  CATEGORY,
  CHECKBOX,
  SELECT,
  MULTISELECT,
  RADIO,
  TEXT,
  TEXTAREA,
  NUMBER,
  DATE,
  YESNO,
  LOCATION,
  MULTILOCATION,
  EXPLANATION,
  TABLE,
  NATIONALITY,
  EMBEDDED_QUESTIONNAIRE,
  LIST,
}
export interface IQuestionSettings {
  numberedItems?: boolean;
  style?: number;
}

export interface IQuestionSettingOption {
  type: string;
  label?: string;
  options?: string[];
}

export const QUESTIONNAIRE_ITEM_DEFAULT_SETTINGS: IQuestionSettings = {
  numberedItems: true,
  style: 0,
};

export const getQuestionnareItemSettingsOptionsPerType = (
  type: QUESTIONNAIRE_ITEM_TYPE,
): Record<keyof IQuestionSettings, IQuestionSettingOption> => {
  let settings = {
    numberedItems: { type: "checkbox", label: "Numbered Items" },
    style: { type: "select", options: ["default"] },
  };
  switch (type) {
    case QUESTIONNAIRE_ITEM_TYPE.CATEGORY:
      settings = {
        ...settings,
        style: { type: "select", options: ["header1", "header2", "header3"] },
      };
      break;
  }
  return settings;
};

export const getItemSettings = (
  item: IQuestionnaireItem,
  options: { withDefaultSettings?: boolean } = {},
) => {
  if (!item) return {};
  const { withDefaultSettings = false } = options;
  item.settings = {
    ...QUESTIONNAIRE_ITEM_DEFAULT_SETTINGS,
    ...(item.settings || {}),
  };

  if (!withDefaultSettings) {
    // remove default settings if defined
    for (const setting in item.settings) {
      const key = setting as keyof IQuestionSettings;
      if (QUESTIONNAIRE_ITEM_DEFAULT_SETTINGS[key] === item.settings[key]) {
        delete item.settings[key];
      }
    }
  }

  if (item.items?.length) {
    item.items = item.items.map((subItem) => {
      subItem.settings = getItemSettings(subItem, options);
      return subItem;
    });
  }
  return item.settings;
};

export interface IQuestionnaireItem<AnswerType = unknown> {
  __v?: number;
  _id: string;
  _v: number;
  answer?: AnswerType;
  conditions?: any[];
  createdAt: string;
  depth?: number;
  description?: string;
  hidden?: boolean;
  isActive: boolean;
  isConfirmed?: boolean;
  isPublished: boolean;
  isRejected?: boolean;
  isRoot: boolean;
  itemNumber?: string;
  items: Array<IQuestionnaireItem>;
  label: string;
  latestVersion?: number;
  legacyQuestionKey?: string;
  legacyQuestionnaireId?: string;
  options?: Array<IQuestionOption>;
  profileName: string;
  profileOId: string;
  reason?: string;
  rules?: Array<ItemRule>;
  settings?: IQuestionSettings;
  type: QUESTIONNAIRE_ITEM_TYPE;
  updatedAt: string;
}

export interface IQuestionOption {
  label: string;
}

interface IQuestionnaireItemWithOptions<T> extends IQuestionnaireItem<T> {
  options: Array<IQuestionOption>;
}

export type ITextQuestionItem = IQuestionnaireItem<string>;
export type INumberQuestionItem = IQuestionnaireItem<number | string>;
export type IDateQuestionItem = IQuestionnaireItem<Date>;
export type ITableQuestionItem = IQuestionnaireItem<
  Array<Record<string, unknown>>
>;
export type IListQuestionItem = IQuestionnaireItem<
  Array<Record<string, unknown>>
>;

export type IRadioQuestion = IQuestionnaireItemWithOptions<string>;
export type ISelectQuestionItem = IQuestionnaireItemWithOptions<string>;
export type ICheckboxQuestionItem = IQuestionnaireItemWithOptions<
  Array<string>
>;
export type IMultiSelectQuestionItem = IQuestionnaireItemWithOptions<
  Array<string>
>;

export const ITEM_RULES_NAME = [
  "required",
  "min",
  "max",
  "len",
  "url",
  "email",
  "lei",
] as const;

export type ItemRuleName = (typeof ITEM_RULES_NAME)[number];

export const isItemRuleName = (value: string): value is ItemRuleName => {
  return ITEM_RULES_NAME.includes(value as ItemRuleName);
};

export interface BaseRule {
  warningOnly?: boolean;
  value?: any;
}

export interface ItemRule extends BaseRule {
  name: ItemRuleName;
}
interface IRuleDetail {
  displayName: string;
  description: string;
  valueType?: string;
}

export const RULE_DETAIL: Record<ItemRuleName, IRuleDetail> = {
  required: {
    displayName: "Required",
    description: "Answering to this question will be mandatory",
  },
  min: {
    displayName: "Minimum length or value",
    description: "",
    valueType: "number",
  },
  max: {
    displayName: "Maximum length or value",
    description: "",
    valueType: "number",
  },
  len: {
    displayName: "Exact length",
    description: "",
    valueType: "number",
  },
  url: {
    displayName: "URL",
    description: "Answer must be a valid URL",
  },
  email: {
    displayName: "Email",
    description: "Answer must be a valid email address",
  },
  lei: {
    displayName: "LEI number",
    description: "Answer must be a valid LEI number",
  },
};

export const RULE_DETAIL_ARRAY = ITEM_RULES_NAME.map((name) => ({
  name,
  ...RULE_DETAIL[name],
}));

export type RuleGenerationFunction<T> = (
  item: IQuestionnaireItem,
  baseRule: BaseRule,
) => T;

export type RuleGenerator<T> = Record<ItemRuleName, RuleGenerationFunction<T>>;

export const getAllowedRulesForType = (
  type?: QUESTIONNAIRE_ITEM_TYPE,
): Array<ItemRuleName> => {
  switch (type) {
    case QUESTIONNAIRE_ITEM_TYPE.TEXT:
      return ["required", "min", "max", "len", "url", "email", "lei"];
    case QUESTIONNAIRE_ITEM_TYPE.TEXTAREA:
      return ["required", "min", "max", "len", "url", "email"];
    case QUESTIONNAIRE_ITEM_TYPE.RADIO:
    case QUESTIONNAIRE_ITEM_TYPE.YESNO:
    case QUESTIONNAIRE_ITEM_TYPE.SELECT:
    case QUESTIONNAIRE_ITEM_TYPE.LOCATION:
    case QUESTIONNAIRE_ITEM_TYPE.NATIONALITY:
      return ["required"];
    case QUESTIONNAIRE_ITEM_TYPE.TABLE:
    case QUESTIONNAIRE_ITEM_TYPE.LIST:
      return ["min", "max"];
    case QUESTIONNAIRE_ITEM_TYPE.DATE:
    case QUESTIONNAIRE_ITEM_TYPE.MULTISELECT:
    case QUESTIONNAIRE_ITEM_TYPE.MULTILOCATION:
    case QUESTIONNAIRE_ITEM_TYPE.CHECKBOX:
    case QUESTIONNAIRE_ITEM_TYPE.NUMBER:
      return ["required", "min", "max"];
    default:
      return [];
  }
};

export const getForbiddenChildrenForType = (
  type?: QUESTIONNAIRE_ITEM_TYPE,
): Array<QUESTIONNAIRE_ITEM_TYPE> => {
  switch (type) {
    case QUESTIONNAIRE_ITEM_TYPE.TABLE:
      return [
        QUESTIONNAIRE_ITEM_TYPE.TABLE,
        QUESTIONNAIRE_ITEM_TYPE.CATEGORY,
        QUESTIONNAIRE_ITEM_TYPE.EMBEDDED_QUESTIONNAIRE,
        QUESTIONNAIRE_ITEM_TYPE.LIST,
      ];
    default:
      return [];
  }
};

export const compoundTypes: readonly QUESTIONNAIRE_ITEM_TYPE[] = [
  QUESTIONNAIRE_ITEM_TYPE.TABLE,
  QUESTIONNAIRE_ITEM_TYPE.EMBEDDED_QUESTIONNAIRE,
  QUESTIONNAIRE_ITEM_TYPE.LIST,
];

export const isQuestionnaireItemCompoundType = (
  type: QUESTIONNAIRE_ITEM_TYPE,
) => compoundTypes.includes(type);

export const itemWithOptions: readonly QUESTIONNAIRE_ITEM_TYPE[] = [
  QUESTIONNAIRE_ITEM_TYPE.CHECKBOX,
  QUESTIONNAIRE_ITEM_TYPE.LOCATION,
  QUESTIONNAIRE_ITEM_TYPE.MULTILOCATION,
  QUESTIONNAIRE_ITEM_TYPE.MULTISELECT,
  QUESTIONNAIRE_ITEM_TYPE.NATIONALITY,
  QUESTIONNAIRE_ITEM_TYPE.RADIO,
  QUESTIONNAIRE_ITEM_TYPE.SELECT,
  QUESTIONNAIRE_ITEM_TYPE.YESNO,
];

export const isItemTypeWithOptions = (type: QUESTIONNAIRE_ITEM_TYPE) =>
  itemWithOptions.includes(type);

export const itemTypeCreatedWithOptions: readonly QUESTIONNAIRE_ITEM_TYPE[] = [
  QUESTIONNAIRE_ITEM_TYPE.RADIO,
  QUESTIONNAIRE_ITEM_TYPE.CHECKBOX,
  QUESTIONNAIRE_ITEM_TYPE.SELECT,
  QUESTIONNAIRE_ITEM_TYPE.MULTISELECT,
];

export const isItemTypeCreatedWithOptions = (type: QUESTIONNAIRE_ITEM_TYPE) =>
  itemTypeCreatedWithOptions.includes(type);

export const itemTypesAllowingFollowUps: readonly QUESTIONNAIRE_ITEM_TYPE[] = [
  QUESTIONNAIRE_ITEM_TYPE.CHECKBOX,
  QUESTIONNAIRE_ITEM_TYPE.LOCATION,
  QUESTIONNAIRE_ITEM_TYPE.MULTILOCATION,
  QUESTIONNAIRE_ITEM_TYPE.MULTISELECT,
  QUESTIONNAIRE_ITEM_TYPE.NATIONALITY,
  QUESTIONNAIRE_ITEM_TYPE.RADIO,
  QUESTIONNAIRE_ITEM_TYPE.SELECT,
  QUESTIONNAIRE_ITEM_TYPE.YESNO,
];

export const doesItemTypesAllowFollowUps = (type?: QUESTIONNAIRE_ITEM_TYPE) =>
  type !== undefined && itemTypesAllowingFollowUps.includes(type);
// should return null if the value is not coherent with the question type
export const getFormattedValueByType = (
  item: IQuestionnaireItem,
  value: any,
): unknown => {
  if (value === undefined) {
    return undefined;
  }
  switch (item.type) {
    case QUESTIONNAIRE_ITEM_TYPE.CATEGORY:
      return null;
    case QUESTIONNAIRE_ITEM_TYPE.NUMBER: {
      if (Number.isFinite(value)) {
        return value;
      }
      return null;
    }
    case QUESTIONNAIRE_ITEM_TYPE.TEXT:
    case QUESTIONNAIRE_ITEM_TYPE.TEXTAREA: {
      if (typeof value === "string") {
        return value.trim();
      }
      return null;
    }
    case QUESTIONNAIRE_ITEM_TYPE.DATE: {
      if (
        !(value instanceof Date) &&
        !(value instanceof dayjs) &&
        (typeof value !== "string" || !dayjs(value).isValid())
      ) {
        return null;
      }
      const date = new Date(value as Date);
      return date;
    }
    case QUESTIONNAIRE_ITEM_TYPE.LIST:
    case QUESTIONNAIRE_ITEM_TYPE.TABLE: {
      if (item.items && Array.isArray(value)) {
        const formatedValue = value.map((answer: any) => {
          const formattedAnswers: Record<string, unknown> = {};
          /*
           * Here we flat the content of the subItems so we can easly ignore category and embeded questionnaires
           */
          const flatSubTree = flattenQuestionnaire(item, {
            flattenCompound: true,
          });
          flatSubTree.forEach((subItem) => {
            if (answer?.[subItem._id]) {
              formattedAnswers[subItem._id] = getFormattedValueByType(
                subItem,
                answer?.[subItem._id],
              );
            }
          });
          return formattedAnswers;
        });
        if (
          formatedValue.some((rowValue) =>
            Object.values(rowValue).some((colValue) => colValue === null),
          )
        ) {
          return null;
        }
        return formatedValue;
      }
      return null;
    }
    default: {
      return value;
    }
  }
};

export const QUESTIONNAIRE_TABLE_MAX_ITEMS = 10;

export interface IFlattenedQuestionnaireItem extends IQuestionnaireItem {
  parentIdx?: number;
}

interface FlatteningOptions {
  keepSubItems?: boolean;
  depth?: number;
  parentIdx?: number;
  flattenCompound?: boolean;
}

export const flattenQuestionnaire = (
  questionnaireNode: IFlattenedQuestionnaireItem,
  {
    // keepSubItems options should never be needed but it makes it easier to check if question has follow up in e2e tests
    keepSubItems = false,
    depth = 0,
    parentIdx = 0,
    // flattenCompound option is there to flatten full tree to facilitate circular dependency lookup
    flattenCompound = false,
  }: FlatteningOptions = {},
): IFlattenedQuestionnaireItem[] => {
  if (!questionnaireNode) throw new Error("Questionnaire is required");
  const isCompoudItem = isQuestionnaireItemCompoundType(questionnaireNode.type);
  const arr: IFlattenedQuestionnaireItem[] = [
    {
      ...questionnaireNode,
      depth,
      items: isCompoudItem || keepSubItems ? questionnaireNode.items : [],
    },
  ];
  if (!isCompoudItem || flattenCompound) {
    questionnaireNode.items?.forEach((node) => {
      const flattenedSubtree = flattenQuestionnaire(
        { ...node, parentIdx },
        {
          depth: depth + 1,
          parentIdx: parentIdx + arr.length,
          keepSubItems,
          flattenCompound,
        },
      );
      arr.push(...flattenedSubtree);
    });
  }
  return arr;
};

interface ItemNumberProcessorParams {
  item: IQuestionnaireItem;
  idx?: number;
  parentItemNumber?: string;
  parentNumberedItems?: boolean;
  depth?: number;
}

export const processItemNumbers = ({
  item,
  idx = 1,
  parentItemNumber = "",
  parentNumberedItems = QUESTIONNAIRE_ITEM_DEFAULT_SETTINGS.numberedItems,
  depth = 0,
}: ItemNumberProcessorParams): IQuestionnaireItem => {
  item.depth = depth;
  const numberedItems =
    (item.settings?.numberedItems ??
      QUESTIONNAIRE_ITEM_DEFAULT_SETTINGS.numberedItems) &&
    parentNumberedItems;
  if (parentNumberedItems && depth) {
    item.itemNumber = `${parentItemNumber}${parentItemNumber ? "." : ""}${idx}`;
  }
  if (item.items?.length) {
    if (item.type === QUESTIONNAIRE_ITEM_TYPE.EMBEDDED_QUESTIONNAIRE) {
      // HACK:the embedded questionnaire should not be displayed by the UI
      item.items[0].items = item.items[0].items.map((subItem, subItemIdx) =>
        processItemNumbers({
          item: subItem,
          idx: subItemIdx + 1,
          parentItemNumber: item.itemNumber,
          parentNumberedItems: numberedItems,
          depth,
        }),
      );
    } else {
      item.items = item.items.map((subItem, subItemIdx) => {
        return processItemNumbers({
          item: subItem,
          idx: subItemIdx + 1,
          parentItemNumber: item.itemNumber,
          parentNumberedItems: numberedItems,
          depth: depth + 1,
        });
      });
    }
  }
  return item;
};

/**
 * This will take a list entry and it's answer,
 * then propogate the answer to the entire entry subtree.
 * This way, each child of the list entry will hold it's own
 * answer.
 *
 */
export const propagateAnswersToChildren = (
  answers: Record<string, unknown>,
  item: IQuestionnaireItem,
) => {
  if (!item.items?.length) return;
  item.items = item.items.map((subItem) => {
    if (answers[subItem._id]) {
      subItem.answer = answers[subItem._id];
    }
    propagateAnswersToChildren(answers, subItem);
    return subItem;
  });
};

/**
 * Alter list item structure to reflect multiple answers to the list.
 *
 * List has a one of a kind structure, it holds only one child when stored to shape the content of one list entry.
 * When retrieving a list, we need to duplicate this child to reflect that list has been answered multiple time.
 */
export const populateListAnswer = (
  listItem: IListQuestionItem,
  answer: Array<Record<string, unknown>>,
) => {
  if (answer?.length) {
    const populatedListItems = answer.map((entryAnswer) => {
      const listEntry = cloneDeep(listItem.items[0]);
      listEntry.isRoot = false;
      propagateAnswersToChildren(entryAnswer, listEntry);
      return listEntry;
    });
    listItem.items = populatedListItems;
  }
};

export const enrichItemByType = (item: IQuestionnaireItem) => {
  if (item.type === QUESTIONNAIRE_ITEM_TYPE.YESNO) {
    item.options = [
      {
        label: "Yes",
      },
      {
        label: "No",
      },
    ];
  }
  return item;
};

export const enrichFollowUps = (item: IQuestionnaireItem) => {
  if (!item.items?.length) return;
  item.items = item.items.map((childItem) => {
    const { conditions = [] } = childItem;
    const isFollowUp = !!conditions.length || isItemTypeWithOptions(item.type);
    childItem.hidden = isFollowUp;
    if (
      !isFollowUp ||
      (isFollowUp &&
        ((!conditions.length && item.answer) || // no condition(wildcard) is display if parent is answered
          conditions.includes(item.answer) || // the answer is a string or a number
          intersection(conditions, item.answer as Array<unknown>).length)) // answer is an array (mostly the case for select/checkboxes and co)
    ) {
      childItem.hidden = false;
      enrichFollowUps(childItem);
    }
    return childItem;
  });
};

const typesHoldingChildAnswer: readonly QUESTIONNAIRE_ITEM_TYPE[] = [
  QUESTIONNAIRE_ITEM_TYPE.LIST,
  QUESTIONNAIRE_ITEM_TYPE.TABLE,
];

export const isTypeHoldingChildAnswer = (type: QUESTIONNAIRE_ITEM_TYPE) =>
  typesHoldingChildAnswer.includes(type);

const itemExpectingAnswer: readonly QUESTIONNAIRE_ITEM_TYPE[] = [
  QUESTIONNAIRE_ITEM_TYPE.CHECKBOX,
  QUESTIONNAIRE_ITEM_TYPE.SELECT,
  QUESTIONNAIRE_ITEM_TYPE.MULTISELECT,
  QUESTIONNAIRE_ITEM_TYPE.RADIO,
  QUESTIONNAIRE_ITEM_TYPE.TEXT,
  QUESTIONNAIRE_ITEM_TYPE.TEXTAREA,
  QUESTIONNAIRE_ITEM_TYPE.NUMBER,
  QUESTIONNAIRE_ITEM_TYPE.DATE,
  QUESTIONNAIRE_ITEM_TYPE.YESNO,
  QUESTIONNAIRE_ITEM_TYPE.LOCATION,
  QUESTIONNAIRE_ITEM_TYPE.MULTILOCATION,
  QUESTIONNAIRE_ITEM_TYPE.TABLE,
  QUESTIONNAIRE_ITEM_TYPE.NATIONALITY,
  QUESTIONNAIRE_ITEM_TYPE.LIST,
];

export const doesItemTypeExpectsAnswer = (type: QUESTIONNAIRE_ITEM_TYPE) =>
  itemExpectingAnswer.includes(type);

export const hasNestedFollowUps = (item: IQuestionnaireItem): boolean => {
  if (item.items?.some(({ conditions }) => !!conditions)) return true;
  if (isItemTypeWithOptions(item.type) && !!item.items?.length) {
    return true;
  }
  return item.items?.some((subItem) => hasNestedFollowUps(subItem));
};
